import { Label, ComboBox, TextFieldV2 } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import React, { useRef, useState, useEffect } from "react";

function Requestor(props) {
  //console.log("Requestor props :::", props);
  
  const isadmin=!props.isAdmin;
  console.log("Requestor props isAdmin after :::", isadmin);

  const [selectedOptionValue, setSelectedOption] = useState(props.cognitoEmail);
  
  let cognitoUsers = props.congitoUser_array;
  const optionsArray = cognitoUsers.map((cognitoUser) => ({
    value: cognitoUser.email,
    key: cognitoUser.email,
  }));

  //console.log("cognitoUsers optionsArray:", optionsArray);
  const handleChangeCognitoUser = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    const selectedUser = cognitoUsers.find(cognitouser => cognitouser.email.toLowerCase() === selectedOption.value.toLowerCase());
    //console.log("Requestor selectedUser:::", selectedUser);
    if (selectedUser) {
      props.onchangeCognitoGroup(selectedUser.group);
    }
    //props.onchangeCognitoGroup
  };
  //console.log("selectedOptionValue:::", selectedOptionValue);

  return (
    <>
      <tr className="editForm_table-row" style={{ border: "hidden", paddingBottom: "15px" }}>
      <td className="td_row_leftvalue">Requestor</td>
      <td className="td_key" style={{ paddingLeft: "15px" }}>
        <ComboBox
          name="cognitoUser"
          id="cognitoUser"
          optionKey="key"
          options={optionsArray}
          disabled={ isadmin }
          //disabled={true}
          onChange={handleChangeCognitoUser}
          defaultValue={optionsArray.find(
            (o) => o.value.toLowerCase() === props.cognitoEmail.toLowerCase()
          )}
        />
      </td>
    </tr>
    </>
  );
}

export default Requestor;