import React, { useState, useEffect } from "react";
import {
  Popover,
  CheckboxV2,
  LuxStylesProvider,
  Label,
} from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

function VTACheckbox(props) {
  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (should_display_vta_checkbox(props) === false) {
      setChecked(false);
    }
    // checking for the editform property from current req
    if (props.editForm === "true") {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  if (should_display_vta_checkbox(props) === false) {
    return null;
  }

  return (
    <>
      {/*  Mobile Gateway  */}
      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <CheckboxV2
            className="verint-CheckboxV2-regular"
            id="VTA"
            style={{ marginTop: "3px" }}
            checked={checked}
            name="VTA"
            type="checkbox"
            value={checked}
            disabled={props.disabled}
            onChange={(e) => {
              setChecked(!checked);
            }}
          ></CheckboxV2>
          <Label id="VTA_label" className="verint-regular-label" forhtml="VTA">
            Text Analytics{" "}
          </Label>
          <OverlayText
            tooltipText={
              "Select this option if you require 'Text Analytics' capability. When selecting this option and starting the lab, leave the lab running for four hours, to allow Text data propagtion."
            }
          ></OverlayText>
        </td>
      </tr>
    </>
  );
}

export default VTACheckbox;
//Validate if current user is part of admin emails
function get_admin_emails(props) {
  //checking the editform property from current request
  if (props.editForm === "true") {
    return true;
  }
  
  var jsonData = props.environemntRecords;
  for (var i = 0; i < jsonData.Count; i++) {
    if (jsonData.Items[i].template_id === "0") {
      // Catch error that can be caused by a null user_email
      try {
        var lower_case_email = props.userEmail.toLowerCase();
      } catch {}
      if (jsonData.Items[i].vta_admin_emails.includes(lower_case_email)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
function should_display_vta_checkbox(props) {
  // temp code to enable VTA checkbox
  var temp_allowed_template_ids = ["34"];
  // A list of admin that can deploy LMS environments from the request form
  var admin_emails = [
    "amit.rao@verint.com",
    "matt.anscombe@verint.com",
    "ullas.gowda@verint.com",
  ];

  // Catch error that can be caused by a null user_email
  try {
    var lower_case_email = props.userEmail.toLowerCase();
    if (
      temp_allowed_template_ids.includes(props.environmentSelected) &&
      get_admin_emails(props)
    ) {
      return true;
    }
  } catch {}
  var jsonData = props.environemntRecords;
  for (var i = 0; i < jsonData.Count; i++) {
    if (
      jsonData.Items[i].group_name.includes(props.group) &&
      jsonData.Items[i].template_id === props.environmentSelected
    ) {
      if (jsonData.Items[i].vta_checkbox === true) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
}
