import React, { useState, useEffect } from "react";
import NumberOfUsers from "./NumberOfUsers";
import { Label, CheckboxV2 } from "@verint/shared-react-components";
import OverlayText from "./OverlayText";
import "@verint/lux_icon_font/verint_lux.css";

function AdditionalVcorpUsers(props) {
  const [AdditionalVcorpUsers, setAdditionalVcorpUsers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (display_AdditionalVcorpUsers_inputs(props) === false) {
      setAdditionalVcorpUsers(false);
    } else if (parseInt(props.editForm) > 0) {
      setAdditionalVcorpUsers(parseInt(props.NumberOfUsers));
    }
  }, [props]);

  // Hide UsersRequired component if the current environment is not an application deployment
  if (display_AdditionalVcorpUsers_inputs(props) === false) {
    return null;
  }

  return (
    <>
      {!props.editForm && (
        <tr className="reqForm_tablerow">
          <td className=" td_row_leftvalue">
            <CheckboxV2
              className="verint-CheckboxV2-regular"
              id="AdditionalVcorpUsers"
              style={{ marginTop: "3px" }}
              name="AdditionalVcorpUsers"
              type="checkbox"
              value={AdditionalVcorpUsers}
              checked={AdditionalVcorpUsers}
              disabled={props.disabled}
              onChange={() => setAdditionalVcorpUsers(!AdditionalVcorpUsers)}
            ></CheckboxV2>

            <Label
              className="verint-regular-label"
              forhtml="AdditionalVcorpUsers"
            >
              Additional VCORP Users?{" "}
            </Label>
            <OverlayText
              tooltipText={
                "This option determines how many Davinci Users your lab will be supplied with. These can be assigned to training delegates or used for testing."
              }
            ></OverlayText>
          </td>
          <td className="td_row_rightvalue">
            <NumberOfUsers
              AdditionalVcorpUsers={AdditionalVcorpUsers}
              editForm={props.editForm}
            />
          </td>
        </tr>
      )}
      {props.editForm && (
        //   if {props.disabled} == true css = editForm_table-row
        <tr className="editForm_table-row">
          <td
            className=" td_row_leftvalue"
            // style={{ alignItems: "center", marginBottom: "15px" }}
          >
            <CheckboxV2
              className="verint-CheckboxV2-regular"
              id="AdditionalVcorpUsers"
              style={{ marginTop: "3px" }}
              name="AdditionalVcorpUsers"
              type="checkbox"
              value={AdditionalVcorpUsers}
              checked={AdditionalVcorpUsers}
              disabled={props.disabled}
              onChange={() => setAdditionalVcorpUsers(!AdditionalVcorpUsers)}
            ></CheckboxV2>

            <Label
              className="verint-regular-label"
              forhtml="AdditionalVcorpUsers"
            >
              Additional VCORP Users?{" "}
            </Label>
            <OverlayText
              tooltipText={
                "This option determines how many Davinci Users your lab will be supplied with. These can be assigned to training delegates or used for testing."
              }
            ></OverlayText>
          </td>
          {/* if {props.disabled} == true css =  td_key */}
          <td className="td_key" style={{ paddingLeft: "15px" }}>
            <NumberOfUsers
              AdditionalVcorpUsers={AdditionalVcorpUsers}
              editForm={props.editForm}
              style={{ width: "85%" }}
            />
          </td>
        </tr>
      )}
    </>
  );
}
export default AdditionalVcorpUsers;

function display_AdditionalVcorpUsers_inputs(props) {
  //checking the editform property from current request
  if (props.editForm === "true") {
    return true;
  }
  
  var jsonData = props.environemntRecords;

  for (var i = 0; i < jsonData.Count; i++) {
    if (
      jsonData.Items[i].group_name.includes(props.group) &&
      jsonData.Items[i].template_id === props.environmentSelected
    ) {
      if (jsonData.Items[i].AdditionalVcorpUsers === true) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
}
