import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

/* Set the value of DaVinci API Credentails CheckBox */
function DaVinciAPICredentialsCheckBox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const open = Boolean(anchorEl);
    const  empTimecollector = props.empTime
    console.log("the props are",props)
    useEffect(() => {
        if(should_display_daVinciApiCredential_checkbox(props) === false){  
            console.log("inside false")
            setChecked(false)
        }
        // checking for the editform property from current req 
        if(props.editForm === "true"){
            setChecked(true)
            setIsDisabled(true)
         }  
    }, [props.environmentSelected])

    useEffect(() => {
        if(empTimecollector === true){
            setIsDisabled(true)
            setChecked(true)      
        }
        else if(empTimecollector === false){
            setIsDisabled(false)
            setChecked(false)   
        }
    }, [empTimecollector]);

    //If selected environment cannot use the checkbox, hide
    if(should_display_daVinciApiCredential_checkbox(props) === false){
        console.log("inside null")
        return null
    }

    // If DaVinci Checkbox is set to true, only then display Call Summary Checkbox
        return <>
        {/*  Call Summary Checkbox  */}
        <tr className='reqForm_tablerow'>
             <td className='td_row_leftvalue'>
        
                    <CheckboxV2 className="verint-CheckboxV2-regular" id="DaVinciApiCredential"  style={{marginTop: "5px"}} 
                        name="DaVinciApiCredential" type = "checkbox" value = {checked} 
                        disabled={isDisabled} checked={checked}  onChange={(e)=>{setChecked(!checked)}} ></CheckboxV2>     

                    <Label id="daVinciApiCredential_label"className="verint-regular-label"   forhtml="DaVinciApiCredential">DaVinci Api Credentials </Label>
                    <OverlayText tooltipText={"Select this option to include DaVinci Api Credentials"}></OverlayText>
    
                </td>
        </tr>
  
    </> 
}

export default DaVinciAPICredentialsCheckBox;

function should_display_daVinciApiCredential_checkbox(props)
{
    //checking the editform property from current request
    if (props.editForm === "true") {
        return true;
    }

    if(props.daVinciChecked === true){
        return true
    }
    //checking the editform property from current request
    if(props.editForm === "true"){
        return true
    }
    return false
}