import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function LiveRecordingCheckbox(props) {
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_lr_checkbox(props) === false){
            props.setLiveRecording(false)
        }   
         // checking for the editform property from current req 
        if(props.editForm === "true"){
            setIsDisabled(true)
        }    
    }, [props.environmentSelected])
     
    useEffect(() => {
        setIsDisabled(props.awsliveRecording);
    }, [props.awsliveRecording]);
     
     // If selected environment is cannot use the checkbox, hide
     if(should_display_lr_checkbox(props) === false){
        return null
    }

    return <>
        {/*  Live Recording  */}
        <tr className='reqForm_tablerow'>
        <td className='td_row_leftvalue'>
                        
                        <CheckboxV2 className="verint-CheckboxV2-regular" id="LiveRecording" style={{marginTop :"3px"}}
                                name="LiveRecording" type = "checkbox"  value = {props.liveRecording}  
                                disabled={isDisabled} checked ={props.liveRecording} 
                                onChange={(e) => {props.setLiveRecording(!props.liveRecording);}}
                                ></CheckboxV2> 
                        
                        <Label id="LiveRecording_label"className="verint-regular-label"   forhtml="LiveRecording">Live Recording </Label>
                        <OverlayText tooltipText={"Select this option if you require the ability to make test calls with an Avaya Aura telephony system."}></OverlayText>
                               
                </td>
             </tr>
            
    </>        
}

export default LiveRecordingCheckbox;

function should_display_lr_checkbox(props)
{
    //checking the editform property from current request
    if (props.editForm === "true") {
        return true;
    }
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].Live_Recording  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

