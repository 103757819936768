import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

/* Set the value of EmployeeTimecollector checkbox */
function EmployeeTimecollector(props) {
    
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        if(display_EmployeeTimecollector(props) === false){
            props.setEmpTime(false)
        }        
    }, [props.environmentSelected])

    // If selected environment is cannot use the checkbox, hide
    if(display_EmployeeTimecollector(props) === false){
        return null
    }

    return <>
        {/*  EmployeeTimecollector Checkbox  */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
    
                 <CheckboxV2 className="verint-CheckboxV2-regular" id="EmployeeTimecollector" style={{marginTop :"3px"}}
                  name="EmployeeTimecollector" type = "checkbox" value = {props.empTime}   onChange={(e)=>{props.setEmpTime(!props.empTime)}} disabled={props.disabled} checked={props.checked}></CheckboxV2>


                    <Label id="EmployeeTimecollector_label"className="verint-regular-label"   forhtml="EmployeeTimecollector"> Employee Time collection/ Queue Performance / Scorecards API's</Label>
                    <OverlayText tooltipText={"Select this option to deploy the Kafka Employee Time Collection API. Note: That this will be enabled post deployment of your lab and you will be notified once it is actioned."}></OverlayText>
                
 
            </td>
        </tr>
    </>    
}

export default EmployeeTimecollector;

function display_EmployeeTimecollector(props)
{
    //checking the editform property from current request
    if (props.editForm === "true") {
        return true;
    }
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].EmployeeTimecollector === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
