import React, { useState, useEffect } from "react";
import { CheckboxV2, Label } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

function MSTeamsAzureCheckbox(props) {
  const [checked, setChecked] = useState(false);

  // If selected environment is cannot use the checkbox, reset value to default
  useEffect(() => {
    if (display_msteams_checkbox(props.environmentSelected) === false) {
      setChecked(true);
    }
    // checking for the editform property from current req
    if (props.editForm === "true") {
      setChecked(true);
    }
  }, [props.environmentSelected]);

  // If selected environment is cannot use the checkbox, hide
  if (display_msteams_checkbox(props.environmentSelected) === false) {
    return null;
  }

  return (
    <>
      {/*  MS Teams Live Recording  */}
      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <CheckboxV2
            className="verint-CheckboxV2-regular"
            id="MSTeamsLiveRecording"
            style={{ marginTop: "3px" }}
            name="MSTeamsLiveRecording"
            type="checkbox"
            checked
            disabled={props.disabled}
            value={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
          ></CheckboxV2>
          <Label
            id="MSTeamsLiveRecording_label"
            className="verint-regular-label"
            forhtml="MSTeamsLiveRecording"
          >
            Teams Recording
          </Label>
          <OverlayText
            tooltipText={"Select this option for Ms Teams"}
          ></OverlayText>
        </td>
      </tr>
    </>
  );
}

export default MSTeamsAzureCheckbox;

function display_msteams_checkbox(template_id) {
 
  // A list of template ids for template ids that can deploy this checkbox
  var allowed_template_ids = ["Azure1", "Azure2"];

  // If current template_id is in the list of template ids, display checkbox
  if (allowed_template_ids.includes(template_id)) {
    return true;
  } else {
    return false;
  }
}
